<template>
  <div class="d-flex">
    <v-btn
      class="text-none gray3--text mr-2 my-4"
      :disabled="!filters.length"
      @click="handleExpand()"
    >
      <v-icon left>mdi-filter-variant</v-icon>

      Filtrar

      <v-icon v-if="localFilters.length" right>
        {{ expanded ? "mdi-chevron-left" : "mdi-chevron-right" }}
      </v-icon>
    </v-btn>

    <v-expand-x-transition v-if="filters.length">
      <div v-show="expanded">
        <div
          class="d-flex align-center fill-width fill-height gray6 pa-2 rounded-lg flex-wrap"
        >
          <div v-for="(item, i) in localFilters" :key="i">
            <v-menu
              ref="menu"
              content-class="mt-2"
              min-width="21rem"
              max-width="21rem"
              :close-on-content-click="false"
              close-on-click
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" class="ma-1" outlined>
                  {{ item.label }}

                  <div v-if="handleAppliedFilter(item)">
                    : {{ handleAppliedFilter(item) }}

                    <v-icon
                      right
                      small
                      @click.stop.prevent="clearFilter(item, i)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </v-chip>
              </template>

              <v-card class="pa-4" dense>
                <v-date-picker
                  v-if="item.type === 'date'"
                  v-model="item.value"
                  :max="_today"
                  class="mb-6"
                  color="primary"
                  no-title
                  flat
                />

                <v-select
                  v-else-if="item.type === 'select'"
                  v-model="item.value"
                  :placeholder="item.label"
                  :items="item.items"
                  class="rounded-lg"
                  prepend-inner-icon="mdi-magnify"
                  item-text="label"
                  return-object
                  outlined
                />

                <v-text-field
                  v-else
                  v-model="item.value"
                  :placeholder="item.label"
                  class="rounded-lg"
                  prepend-inner-icon="mdi-magnify"
                  outlined
                />

                <div class="d-flex">
                  <v-spacer />

                  <v-btn
                    class="text-none"
                    plain
                    @click="
                      cancelChanges(item, i);
                      $refs.menu[i].save();
                    "
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    class="text-none"
                    color="primary"
                    @click="
                      applyFilter(item);
                      $refs.menu[i].save();
                    "
                  >
                    Aplicar
                  </v-btn>
                </div>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>
    </v-expand-x-transition>
  </div>
</template>

<script>
const moment = require("moment");
moment.locale("pt-BR");

export default {
  data() {
    return {
      expanded: false,
      localFilters: [],
      appliedFilters: [],
    };
  },

  props: {
    filters: {
      type: Array,
      default: () => [],
      /**
       * @property {String} label - label of input
       * @property {String} key - key of filter
       * @property {Array} items - optional: array of select options
       * @property {Array} menu - optional: array of menu options
       * * @property {String} label - select text
       * * @property {String} value - returned value on select
       */
    },
  },

  beforeMount() {
    this.localFilters = structuredClone(this.filters);
  },

  watch: {
    filters: {
      handler(val) {
        this.localFilters = structuredClone(val);
      },
      deep: true,
    },
  },

  computed: {
    _today() {
      return moment().format("YYYY-MM-DD");
    },
  },

  methods: {
    handleExpand() {
      this.expanded = !this.expanded;
    },

    applyFilter(data) {
      this.$emit("submit", {
        ...data,
        value: typeof data.value === "object" ? data.value.value : data.value,
      });

      const index = this.appliedFilters.findIndex((el) => el.key === data.key);

      if (index >= 0 && !data.value) {
        this.appliedFilters.splice(index, 1);
        return;
      }

      if (index >= 0) this.appliedFilters[index].value = data.value;
      else this.appliedFilters.push(structuredClone(data));
    },

    cancelChanges(data, index) {
      const appliedIndex = this.appliedFilters.findIndex(
        (el) => el.key === data.key
      );

      if (appliedIndex >= 0)
        this.localFilters[index].value =
          this.appliedFilters[appliedIndex].value;
    },

    handleAppliedFilter(data) {
      const index = this.appliedFilters.findIndex((el) => el.key === data.key);

      if (index >= 0) {
        if (typeof this.appliedFilters[index].value === "object")
          return this.appliedFilters[index].value.label;

        if (this.appliedFilters[index].type === "date")
          return moment(this.appliedFilters[index].value).format("DD/MM/YYYY");

        return this.appliedFilters[index].value;
      }

      return false;
    },

    clearFilter(data, index) {
      this.localFilters[index].value = "";
      this.$emit("submit", this.localFilters[index]);

      const appliedIndex = this.appliedFilters.findIndex(
        (el) => el.key === data.key
      );

      if (appliedIndex >= 0) this.appliedFilters.splice(appliedIndex, 1);
    },
  },
};
</script>

<style></style>
