<template>
  <v-container fill-height>
    <div v-if="!attendance" class="d-flex align-center justify-center my-10">
      <v-progress-circular color="primary" indeterminate />
    </div>

    <div v-else class="d-flex flex-column fill-width">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="4" xl="3">
          <CustomChat
            class="mb-4"
            :chat="_chat"
            :attendant="{
              name: attendance.attendant.name,
              icon: 'mdi-account',
            }"
            :height="'80vh'"
            :finalizeLabel="'Finalizar Atendimento'"
            :readMode="readMode"
            @send-message="handleSendMessage($event)"
            @finalize="finalizeAttendance()"
            @attach-file="uploadFile($event)"
          />

          <div v-if="readMode" class="d-flex align-center justify-center">
            <v-btn @click="finalizeAttendance()" class="text-none" text>
              Finalizar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { uploadFile } from "@/services/files";
import { handleAlert, showError, handleFinishAttendance } from "@/utils";
import CustomChat from "@/components/customChat/CustomChat";

export default {
  name: "Attendance",

  components: {
    CustomChat,
  },

  data() {
    return {
      readMode: false,
      chat: {
        messages: [],
      },
    };
  },

  computed: {
    ...mapState("socket", ["attendance", "messages", "inAttendance", "code"]),

    _code() {
      return this.$route.query.code || this.attendance?.code || this.code;
    },

    _chat() {
      return {
        ...this.chat,
        messages: this.messages,
      };
    },
  },

  watch: {
    attendance(val, oldVal) {
      if (!!oldVal && !val && !this.inAttendance) {
        const params = {
          attendanceID: oldVal._id,
          deskID: oldVal.desk._id,
        };
        const query = {
          code: oldVal.code || this._code,
        };

        this.handleAlert("Atendimento finalizado pelo atendente", "info");

        this.handleRating(params, query);
      }
    },
  },

  methods: {
    ...mapActions("socket", [
      "setAttendance",
      "joinAttendance",
      "finishAttendance",
      "sendMessage",
      "setMessages",
    ]),

    handleSendMessage(data) {
      const payload = {
        attendanceID: this.attendance._id,
        text: data.message,
      };

      this.sendMessage({
        data: payload,
        callback: (error) => {
          if (error) {
            console.log("sendMessage:error", error);
            throw new Error(error);
          }
        },
      });
    },

    async uploadFile(event) {
      try {
        let formData = new FormData();
        formData.append("file", event.file);

        const { data } = await uploadFile(formData);
        this.handleSendMessage({ message: data.publicUrl });
      } catch (error) {
        this.handleAlert(
          error.response?.data?.message || error.data.message,
          "error"
        );
      }
    },

    handleJoin() {
      try {
        const payload = {
          protocol: this.attendance.protocol,
        };

        this.joinAttendance({
          data: payload,
          callback: (error) => {
            if (error) {
              console.log("joinAttendance:error", error);
              throw new Error(error);
            }
          },
        });
      } catch (error) {
        this.showError(error);
      }
    },

    finalizeAttendance() {
      const msg = {
        message: "Atendimento finalizado pelo solicitante",
      };

      const payload = {
        attendanceID: this.attendance._id,
        deskID: this.attendance.desk._id,
      };

      this.handleSendMessage(msg);
      this.handleFinishAttendance();

      this.handleRating(payload);
    },

    handleRating(params, query) {
      this.$router.push(
        {
          name: "Rating",
          params,
          query,
        },
        () => {},
        () => {}
      );
    },

    showError,
    handleAlert,
    handleFinishAttendance,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
