<template>
  <v-form v-model="valid" ref="form">
    <v-autocomplete
      ref="autocomplete"
      v-model="selectedItems"
      :items="_orderedItems"
      :search-input.sync="search"
      :multiple="multiple"
      :loading="loading"
      :disabled="disabled || loading"
      :required="required"
      :hide-details="hideDetails"
      :hide-no-data="true"
      :placeholder="placeholder"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      :rules="rules"
      :clearable="clearable"
      outlined
      single-line
      open-on-clear
      return-object
      @focus="onFocus"
      @blur="onBlur"
      @change="onSelectChange"
    >
      <!-- @TODO: Ajustar mensagem, pois aparece enquanto está fazendo o loading... -->
      <template v-slot:no-data>
        <v-list two-line>
          <v-list-item>
            <v-list-item-title>
              {{ "Nenhum item encontrado..." }}
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item v-if="createIfNotFound">
            <v-list-item-title>
              <v-btn
                block
                outlined
                dense
                x-small
                color="primary"
                @click="$emit('create-if-not-found')"
              >
                criar {{ search }}</v-btn
              >
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:selection="{ attr, on, item, selected, index }">
        <v-chip
          v-if="!focused && index === 0"
          v-bind="attr"
          :input-value="selected"
          color="primary"
          class="white--text"
          v-on="on"
          small
        >
          <span
            class="item-selector__chip-text"
            :class="{ block }"
            :title="item[itemText]"
            v-text="item[itemText]"
          ></span>
        </v-chip>

        <v-tooltip v-else-if="!focused && index === 1" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="white--text"
              small
            >
              <span v-text="_additionalSelectedValue"></span>
            </v-chip>
          </template>
          <div class="d-flex flex-column">
            <span
              class="caption"
              v-for="(item, i) in _additionalSelectedItems"
              :key="i"
            >
              - {{ item[itemText] }};
            </span>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item="{ item }">
        <v-list-item-avatar class="mr-2" color="primary" size="1.75rem">
          <v-icon x-small class="white--text">
            {{ getInitials(item) }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <template v-slot:append-item>
        <div class="d-flex justify-center mt-2">
          <v-btn
            class="text-none"
            color="primary"
            :loading="loading"
            :disabled="_orderedItems.length >= count"
            depressed
            small
            @click="loadMoreItems"
          >
            Carregar Mais
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script>
export default {
  name: "ItemSelector",
  props: {
    value: {
      type: [Array, Object, String],
      required: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      required: true,
      default: 0, // Total de itens disponíveis
    },
    label: {
      type: String,
      default: "Selecione os itens",
    },
    placeholder: {
      type: String,
      default: "Pesquisar...",
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "_id",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    createIfNotFound: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [String, Boolean],
      default: "auto",
    },
  },
  data() {
    return {
      valid: false,
      searchInterval: null,
      selectInterval: null,
      search: "",
      focused: false,
    };
  },
  watch: {
    search(value, oldValue) {
      if (value === oldValue || (!value && !oldValue)) {
        return;
      }

      if (this.searchInterval) {
        clearInterval(this.searchInterval);
      }

      this.searchInterval = setTimeout(() => {
        this.$emit("search", value || "");
      }, 500);
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.selectInterval) {
          clearInterval(this.selectInterval);
        }
        this.selectInterval = setTimeout(() => {
          this.$emit("input", value);
        }, 500);
      },
    },

    _orderedItems() {
      const selectedValues = [];

      if (this.selectedItems) {
        // Coleta os valores dos itens selecionados (seja array ou single)
        selectedValues.push(
          ...[].concat(this.selectedItems).map((item) => item[this.itemValue])
        );
      }

      // Filtra os itens não selecionados
      const notSelected = this.items.filter(
        (item) => !selectedValues.includes(item[this.itemValue])
      );

      const selected = [].concat(this.selectedItems || []);

      // Retorna os selecionados no topo seguidos pelos itens da busca
      return [...selected, ...notSelected];
    },

    _additionalSelectedValue() {
      if (!this.multiple) {
        return;
      }

      return this.selectedItems?.length > 1
        ? `+${this.selectedItems.length - 1}`
        : "";
    },

    _additionalSelectedItems() {
      if (!this.multiple) {
        return;
      }

      const haveAdditionalSelectedItems = this.selectedItems?.length > 1;
      if (!haveAdditionalSelectedItems) {
        return [];
      }

      // retornar sem o primeiro item
      return this.selectedItems.slice(1);
    },
  },
  beforeMount() {
    if (!this.selectedItems) {
      this.selectedItems = this.multiple ? [] : null;
    }
  },

  beforeDestroy() {
    this.resetData();
  },

  methods: {
    getInitials(item) {
      if (!item?.[this.itemText]) {
        return "";
      }

      const onlyText = item[this.itemText]
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/\s+/g, " ");

      const splitted = onlyText.split(" ");

      if (splitted.length > 1) {
        return `${splitted[0][0] || ""}${splitted[1][0] || ""}`.toUpperCase();
      }

      return `${splitted[0][0] || ""}`.toUpperCase();
    },

    resetData() {
      this.search = "";
      this.selectedItems = this.multiple ? [] : null;
    },

    loadMoreItems() {
      this.$emit("paginate", this.search);
    },

    onSelectChange() {
      // Se não for múltipla seleção, remove o foco ao selecionar um item
      if (!this.multiple) {
        this.$nextTick(() => {
          this.$refs.autocomplete.blur(); // Remove o foco do campo
        });
      }
    },

    onFocus() {
      this.focused = true;
    },

    onBlur() {
      this.focused = false;

      if (this.search?.length) {
        this.search = "";
      }
    },

    validate() {
      if (!this.rules.length) return true;

      return this.$refs.form.validate();
    },

    resetValidate() {
      this.$refs.form.resetValidate();
    },
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
