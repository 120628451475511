import api from "./index";

export async function getCounters(params) {
  return api.get(`/desks`, { params: params });
}

export async function getCounter(id) {
  return api.get(`/desks/${id}`);
}

export async function getCounterStatus(deskID) {
  return api.get(`/desks/${deskID}/status`);
}
