<template>
  <v-container class="table">
    <div class="d-flex align-center mb-1">
      <v-btn elevation="2" @click="handleback()">
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <div class="ml-5 text-h6 font-weight-light text-center">
        Histórico de Atendimento
      </div>
      <v-spacer />

      <FilterSelector
        :filters="_filterOptions"
        @submit="handleFilter($event)"
      />
    </div>

    <CustomTable
      ref="customTable"
      :items="historic"
      :headers="headers"
      :offset="pagination.offset"
      :limit="pagination.limit"
      :total="pagination.total"
      :disableSort="true"
      @change:pagination="handlePagination($event)"
      @view="handleShowChat($event)"
    />
  </v-container>
</template>

<script>
import { getHistoric } from "@/services/chatHistoric";
import CustomTable from "@/components/customTable/CustomTable.vue";
import FilterSelector from "@/components/filterSelector/FilterSelector";

export default {
  name: "ChatHistoric",

  components: {
    CustomTable,
    FilterSelector,
  },

  data() {
    return {
      interval: null,
      loading: true,
      filters: {
        search: "",
        protocol: "",
      },
      pagination: {
        limit: 15,
        offset: 0,
        total: 0,
      },
      headers: [
        {
          text: "Protocolo",
          value: "protocol",
        },
        {
          text: "Balcão",
          value: "desk",
        },
        {
          text: "Atendente",
          value: "attendant",
        },
        {
          text: "Data do atendimento",
          value: "createdAt",
        },
        {
          text: "Solucionado",
          value: "rating",
        },
        {
          text: "Via de Atendimento",
          value: "attendanceFormat",
        },
        {
          value: "actions",
          type: "actions",
          align: "center",
          width: "2.5rem",
        },
      ],
      historic: [],
    };
  },

  beforeMount() {
    this.getHistoric();
  },

  computed: {
    _filterOptions() {
      return [
        {
          label: "Protocolo",
          key: "protocol",
        },
      ];
    },
  },

  methods: {
    async getHistoric() {
      try {
        this.loading = true;

        const payload = {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...(this.filters.protocol && { protocol: this.filters.protocol }),
        };

        const { data, headers } = await getHistoric(payload);
        this.historic = data.map((el) => ({
          ...el,
          actions: [
            {
              value: "menu",
              icon: "mdi-dots-vertical",
              menu: [
                {
                  label: "Visualizar",
                  value: "view",
                },
              ],
            },
          ],
          attendanceFormat:
            el.attendanceFormat === "chat" ? "Chat" : "Video Chamada",
          createdAt: new Date(el.createdAt).toLocaleDateString(),
          desk: el?.desk?.name ? el?.desk?.name : "Atendimento via E-mail",
          attendant: el?.attendant?.name
            ? el?.attendant?.name
            : "Sem Atendente",
          rating: el?.rating?.answered ? "Sim" : "Nâo",
        }));

        this.pagination.total = parseInt(headers["x-count"]);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleFilter(event) {
      this.filters[event.key] = event.value;
      this.pagination.offset = 0;
      this.$refs.customTable.resetPagination();

      this.getHistoric();
    },

    handleSearch() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setTimeout(() => {
        this.getHistoric();
      }, 500);
    },

    handlePagination(event) {
      this.pagination.offset = event.offset;
      this.getHistoric();
    },

    handleback() {
      this.$router.push("/home").catch(() => {});
    },
    handleShowChat(data) {
      const id = data._id;
      this.$router.push(`/attendance/${id}`);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
