import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

import Home from "../views/home/Home.vue";
import ChatBot from "../views/chatBot/ChatBot.vue";
import Attendance from "../views/attendance/Attendance.vue";
import Queue from "../views/queue/Queue.vue";
import RatingVue from "@/views/rating/Rating.vue";
import RatingFormVue from "@/views/rating/partials/RatingForm.vue";
import ChatHistoricVue from "@/views/chatHistoric/ChatHistoric.vue";
import AttendanceAuthorize from "@/views/attendanceAuthorize/AttendanceAuthorize.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/chat-bot",
    name: "ChatBot",
    component: ChatBot,
  },
  {
    path: "/attendance/:id",
    name: "Attendance",
    component: Attendance,
  },
  {
    path: "/attendance/:id/authorize",
    name: "AttendanceAuthorize",
    component: AttendanceAuthorize,
  },
  {
    path: "/queue",
    name: "Queue",
    component: Queue,
  },
  {
    path: "/rating/:attendanceID/:deskID",
    name: "Rating",
    component: RatingVue,
  },
  {
    path: "/rating-form/:attendanceID/:deskID",
    name: "RatingForm",
    component: RatingFormVue,
  },
  {
    path: "/historic",
    name: "Historic",
    component: ChatHistoricVue,
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
