import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import socket from "./socket";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "client-bv-default",
  storage: window.localStorage,
  reducer: () => ({
    socket: {},
  }),
});

export default new Vuex.Store({
  state: {
    module: "",
  },

  getters: {
    module: ({ module }) => module,
  },

  mutations: {},

  actions: {},

  plugins: [vuexLocal.plugin],

  modules: {
    socket,
  },
});
