<template>
  <v-container>
    <div class="d-flex flex-column">
      <v-card outlined rounded elevation="19" class="transparent-card">
        <v-row class="mb-10" justify="center">
          <v-col cols="12" sm="10" md="8" lg="6" xl="5">
            <v-card class="pa-8 transparent-card" height="100%" flat>
              <v-img :src="require('@/assets/chat-person.png')" />
            </v-card>
          </v-col>
          <v-col cols="12" sm="10" md="8" lg="6" xl="5">
            <v-card class="pa-8 mt-7 mr-6" height="100%" flat>
              <div class="header d-flex flex-column mb-2">
                <div class="text-h4 font-weight-light mb-10">
                  Pesquisa de Satisfação
                </div>
              </div>
              <div class="content">
                <v-form ref="form" class="mt-4">
                  <v-row>
                    <span class="ml-4 text-body1 font-weight-light">{{
                      question
                    }}</span>
                    <v-col cols="12">
                      <v-textarea
                        name="input-7-1"
                        filled
                        label="Inserir texto aqui"
                        auto-grow
                        v-model="form.feedback"
                      ></v-textarea>
                    </v-col>
                    <span class="ml-4 text-body1 font-weight-light"
                      >Avalie nosso atendimento</span
                    >
                    <v-col cols="12">
                      <v-rating
                        color="primary"
                        background-color="gray4"
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        hover
                        length="5"
                        size="30"
                        v-model="form.stars"
                      ></v-rating>
                    </v-col>
                  </v-row>
                  <div class="footer d-flex mt-6">
                    <v-spacer />
                    <v-btn
                      class="ml-3 font-weight-regular"
                      elevation="1"
                      color="success"
                      @click="submit()"
                    >
                      <span> Concluir </span>
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { handleAlert } from "@/utils";
import { mapState } from "vuex";
import { getRating } from "@/services/rating";
import { getAttendance, updateAttendance } from "@/services/attendance";
export default {
  name: "RatingForm",
  data() {
    return {
      question: "",
      form: {
        feedback: "",
        stars: 0,
        answered: "",
      },
    };
  },
  computed: {
    ...mapState("socket", ["attendance", "code"]),

    _attendanceId() {
      return this.$route.params.attendanceID;
    },

    _code() {
      return this.$route.query.code || this.attendance?.code || this.code;
    },
  },
  created() {
    if (this._attendanceId) {
      this.getData();
    }
  },
  methods: {
    async submit() {
      const id = this._attendanceId;
      const values = {
        rating: {},
      };

      if (this.form.answered !== null && this.form.answered !== undefined) {
        values.rating.answered = this.form.answered;
      }

      if (this.form.stars) {
        values.rating.stars = this.form.stars.toString();
      }

      if (this.form.feedback) {
        values.rating.feedback = this.form.feedback;
      }
      try {
        await updateAttendance(id, values);
        this.handleAlert("Atendimento avaliado com sucesso!", "success");
        this.$router.push({ path: "/home" }).catch(() => {});
      } catch (error) {
        this.handleAlert(
          error.response?.data?.message || error.message,
          "error"
        );
      }
    },
    async getData() {
      try {
        const response = await getAttendance(this._attendanceId, {
          code: this._code,
        });

        this.form.answered = response.data.rating.answered;
      } catch (error) {
        console.log(error);
        // this.$router.push({ path: "/home" }).catch(() => {});
      }
    },
    async getQuestion() {
      try {
        const { data } = await getRating();
        if (data.length) this.question = data[0].text;
      } catch (error) {
        this.handleAlert(
          error.response?.data?.message || error.message,
          "error"
        );
      }
    },
    handleAlert,
  },
  beforeMount() {
    this.getQuestion();
  },
};
</script>

<style src="./style.scss" lang="scss" />
