export function handleAlert(message, type, fixed, timeout) {
  this.$root.$emit("alert", { message, type, fixed, timeout });
}

export function handleFinishAttendance() {
  this.$root.$emit("attendance:finish");
}

export function showError(error) {
  const message =
    error?.response?.data?.message ||
    error?.data?.message ||
    error?.message ||
    error;
  this.$root.$emit("alert", { message, error });
}

export function formatDocument(value) {
  if (!value) return;

  if (value.length === 11)
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  if (value.length === 14)
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

  return value;
}

export function fetchTotalPages(total, limit = 15) {
  let value = total / limit;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}
