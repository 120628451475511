<template>
  <v-container>
    <div class="d-flex flex-column fill-width">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="4" xl="3">
          <div class="mx-auto" style="max-width: 300px">
            <h2>Digite o código de acesso enviado para o seu email</h2>
            <v-btn
              class="my-4"
              text
              block
              @click="handleResend"
              :disabled="!showResend"
              :style="{ opacity: showResend ? 1 : 0 }"
            >
              <v-icon> mdi-refresh </v-icon>
              Reenviar código
            </v-btn>
            <v-otp-input
              v-model="code"
              :disabled="loading"
              @finish="handleFinish"
            ></v-otp-input>

            <v-overlay absolute :value="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { getAttendance, resendAttendanceCode } from "@/services/attendance";
import { handleAlert } from "@/utils";
import { mapActions } from "vuex";

export default {
  name: "AttendanceAuthorize",

  components: {},

  data() {
    return {
      loading: false,
      showResend: false,
      code: "",
    };
  },

  created() {
    setTimeout(() => {
      this.showResend = true;
    }, 5000);
  },

  beforeDestroy() {},

  computed: {},

  methods: {
    ...mapActions("socket", ["setAttendance"]),

    async handleFinish() {
      this.loading = true;

      try {
        const attendanceID = this.$route.params.id;

        const { data } = await getAttendance(attendanceID, { code: this.code });
        const { code } = data;

        this.setAttendance(data);
        this.$router
          .push({
            path: `/attendance/${attendanceID}`,
            query: { code },
          })
          .catch(() => {});
      } catch (error) {
        this.handleAlert(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message,
          "error"
        );
      } finally {
        this.loading = false;
      }
    },

    async handleResend() {
      this.loading = true;
      this.showResend = false;
      try {
        const attendanceID = this.$route.params.id || this.attendance?._id;
        await resendAttendanceCode(attendanceID);

        this.handleAlert(
          "Código reenviado com sucesso, verifique o seu email",
          "success"
        );
      } catch (error) {
        console.log(error.data);
        this.handleAlert(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message,
          "error"
        );
      } finally {
        this.loading = false;

        setTimeout(() => {
          this.showResend = true;
        }, 3000);
      }
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
