<template>
  <v-dialog v-model="_internalValue" max-width="400px" persistent>
    <v-card class="pa-6">
      <v-card-title class="pa-2 mb-2 title">
        Escolha abaixo a instância e o balcão de atendimento
      </v-card-title>

      <v-form @submit.prevent="submit">
        <slot name="content">
          <div class="d-flex flex-column pa-2">
            <ItemSelector
              ref="groupSelector"
              class="mt-2"
              v-model="selectors.groups.selected"
              :items="selectors.groups.items"
              :label="selectors.groups.label"
              :placeholder="selectors.groups.placeholder"
              :count="selectors.groups.count"
              :multiple="selectors.groups.multiple"
              :clearable="selectors.groups.clearable"
              :item-value="selectors.groups.itemValue"
              :item-text="selectors.groups.itemText"
              :disabled="loading"
              :rules="_rules"
              @search="selectors.groups.onSearch"
              @paginate="selectors.groups.onPaginate"
            />

            <ItemSelector
              ref="subgroupSelector"
              class="mt-2"
              v-model="selectors.subgroups.selected"
              :items="selectors.subgroups.items"
              :label="selectors.subgroups.label"
              :placeholder="selectors.subgroups.placeholder"
              :count="selectors.subgroups.count"
              :multiple="selectors.subgroups.multiple"
              :clearable="selectors.subgroups.clearable"
              :item-value="selectors.subgroups.itemValue"
              :item-text="selectors.subgroups.itemText"
              :disabled="!selectors.groups.selected || loading"
              :rules="_rules"
              @search="selectors.subgroups.onSearch"
              @paginate="selectors.subgroups.onPaginate"
            />

            <ItemSelector
              ref="deskSelector"
              class="mt-2"
              v-model="selectors.desks.selected"
              :items="selectors.desks.items"
              :label="selectors.desks.label"
              :placeholder="selectors.desks.placeholder"
              :count="selectors.desks.count"
              :multiple="selectors.desks.multiple"
              :clearable="selectors.desks.clearable"
              :item-value="selectors.desks.itemValue"
              :item-text="selectors.desks.itemText"
              :disabled="!selectors.subgroups.selected || loading"
              :rules="_rules"
              @search="selectors.desks.onSearch"
              @paginate="selectors.desks.onPaginate"
            />
          </div>
        </slot>
      </v-form>

      <v-card-actions class="actions">
        <v-btn
          text
          class="actions-submit"
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Iniciar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ItemSelector from "@/components/itemSelector/ItemSelector.vue";
import { getJurisdictions } from "@/services/jurisdictions";
import { getCourts } from "@/services/courts";
import { getCounters } from "@/services/counters";
import { handleAlert } from "@/utils";

export default {
  name: "FilterDrawer",

  components: {
    ItemSelector,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    _rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  watch: {
    "selectors.groups.selected": {
      handler(value) {
        // console.log("updated:groups");
        this.filters.groups = value;
        this.selectors.subgroups.page = 0;
        this.selectors.subgroups.count = 0;
        this.selectors.subgroups.items = [];
        this.selectors.subgroups.selected = null;

        if (value) {
          this.handleFetchSubgroups();
        }
      },
      deep: true,
    },
    "selectors.subgroups.selected": {
      handler(value) {
        // console.log("updated:subgroups");
        this.filters.subgroups = value;
        this.selectors.desks.page = 0;
        this.selectors.desks.count = 0;
        this.selectors.desks.items = [];
        this.selectors.desks.selected = null;

        if (value) {
          this.handleFetchDesks();
        }
      },
      deep: true,
    },
    "selectors.desks.selected": {
      handler(value) {
        // console.log("updated:desks");
        this.filters.desks = value;
      },
      deep: true,
    },
  },

  data() {
    return {
      dialog: false,
      filters: {},
      selectors: {
        groups: {
          label: "Fórum / Prédio",
          placeholder: "Digite para buscar Forúns / Predios...",
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: null,
          loading: false,
          multiple: false,
          clearable: false,
          count: 0,
          page: 0,
          onSearch: this.onSearchGroups,
          onPaginate: this.fetchMoreDesks,
        },
        subgroups: {
          label: "Vara / Setor",
          placeholder: "Digite para buscar vara / setores...",
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: null,
          loading: false,
          multiple: false,
          clearable: false,
          count: 0,
          page: 0,
          onSearch: this.onSearchSubgroups,
          onPaginate: this.fetchMoreSubgroups,
        },
        desks: {
          label: "Balcão",
          placeholder: "Digite para buscar Balcões...",
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: null,
          loading: false,
          multiple: false,
          clearable: false,
          count: 0,
          page: 0,
          onSearch: this.onSearchDesks,
          onPaginate: this.fetchMoreDesks,
        },
      },
    };
  },

  methods: {
    submit() {
      if (
        !this.$refs.groupSelector.validate() ||
        !this.$refs.subgroupSelector.validate() ||
        !this.$refs.deskSelector.validate()
      )
        return;

      this.$emit("submit", this.filters);
    },
    emitInput(value) {
      this.$emit("input", value);
    },
    close() {
      this._internalValue = false;
    },
    async handleFetchGroups(search = "", increment = false) {
      this.selectors.groups.loading = true;

      try {
        const groupID = this.selectors.groups.selected?._id;

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.groups.page * 10,
          ...(!!groupID && { nin: [groupID] }),
          ...(!!search?.length && { search }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getJurisdictions(payload);

        this.selectors.groups.count = Number(count);

        if (increment) {
          this.selectors.groups.items.push(...data);
        } else {
          this.selectors.groups.items = data;
        }
      } catch (error) {
        console.log("Falha ao obter grupos", error);
        this.handleAlert(
          error?.data?.message || "Falha ao obter grupos",
          "error"
        );
      } finally {
        this.selectors.groups.loading = false;
      }
    },

    async handleFetchSubgroups(search = "", increment = false) {
      this.selectors.subgroups.loading = true;

      try {
        const groupID = this.selectors.groups.selected?._id;
        const subgroupID = this.selectors.subgroups.selected?._id;

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.subgroups.page * 10,
          ...(!!subgroupID && { nin: [subgroupID] }),
          ...(!!groupID && { groupIDs: [groupID] }),
          ...(!!search?.length && { search }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getCourts(payload);

        this.selectors.subgroups.count = Number(count);

        if (increment) {
          this.selectors.subgroups.items.push(...data);
        } else {
          this.selectors.subgroups.items = data;
        }
      } catch (error) {
        console.log("Falha ao obter subgrupos", error);
        this.handleAlert(
          error?.data?.message || "Falha ao obter subgrupos",
          "error"
        );
      } finally {
        this.selectors.subgroups.loading = false;
      }
    },

    async handleFetchDesks(search = "", increment = false) {
      if (!this.selectors.subgroups.selected) {
        return;
      }

      this.selectors.desks.loading = true;

      try {
        const subgroupID = this.selectors.subgroups.selected?._id;
        const deskID = this.selectors.desks.selected?._id;

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.desks.page * 10,
          ...(!!deskID && { nin: [deskID] }),
          ...(!!search?.length && { search }),
          ...(!!subgroupID && { subgroupIDs: [subgroupID] }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getCounters(payload);

        this.selectors.desks.count = Number(count);

        if (increment) {
          this.selectors.desks.items.push(...data);
        } else {
          this.selectors.desks.items = data;
        }
      } catch (error) {
        console.log("Falha ao obter balcões", error);
        this.handleAlert(
          error?.data?.message || "Falha ao obter balcões",
          "error"
        );
      } finally {
        this.selectors.desks.loading = false;
      }
    },

    onSearchGroups(search) {
      this.selectors.groups.page = 0;
      this.selectors.groups.count = 0;
      this.selectors.groups.items = [];

      this.handleFetchGroups(search);
    },

    onSearchSubgroups(search) {
      this.selectors.subgroups.page = 0;
      this.selectors.subgroups.count = 0;
      this.selectors.subgroups.items = [];

      this.handleFetchSubgroups(search);
    },

    onSearchDesks(search) {
      this.selectors.desks.page = 0;
      this.selectors.desks.count = 0;
      this.selectors.desks.items = [];

      this.handleFetchDesks(search);
    },

    fetchMoreGroups(search) {
      this.selectors.groups.page++;
      this.handleFetchGroups(search, true);
    },

    fetchMoreSubgroups(search) {
      this.selectors.subgroups.page++;
      this.handleFetchSubgroups(search, true);
    },

    fetchMoreDesks(search) {
      this.selectors.desks.page++;
      this.handleFetchDesks(search, true);
    },

    handleAlert,
  },

  mounted() {
    this.handleFetchGroups();
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
